import React from "react";
import { Link } from "react-router-dom";

export const Ul = ({head, options, className }) => {
    return (
        <>
            {head ? <h3>{head}</h3> : '' }
            <ul className={ className || "_301_hover"}>
                {options.map( (op,i) => (
                    <li key={i}>
                        <Link to={op.href} target={op?.external && "_blank"}>{op.title}</Link>
                    </li>
                ))}
            </ul>
        </>
    );
}
