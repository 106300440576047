import { apiCall } from "./api";

const getStorageVar = (name) => {
    return JSON.parse(localStorage.getItem(name)) || [];
}

const checkAuth = async () => {
    if( !localStorage.getItem('token')){
        return false;
    }else{
        const isAuth = await apiCall(`${process.env.REACT_APP_API_URL}/es/check`,'get',[],true);
        if(!isAuth?.valid){
            localStorage.removeItem("token");
            localStorage.removeItem("user");
        }
        return isAuth;
    }
}

const login = async (email, password) => {
    const res = await apiCall(`${process.env.REACT_APP_API_URL}/es/login`, 'post', {email,password});
    if(res.status !== 'error'){
        localStorage.setItem("token", JSON.stringify(res.authorization));
        localStorage.setItem("user", JSON.stringify(res.user));
        return true;
    }else{
        return res;
    }
}

const logout = async () => {
    if( !localStorage.getItem('token')){
        return false;
    }else{
        const res = await apiCall(`${process.env.REACT_APP_API_URL}/es/logout`,'delete',{logout:true},true);
        if(res.status !== 'error'){
            localStorage.clear();
            return true;
        }
    }
}

export {
    getStorageVar,
    checkAuth,
    login,
    logout
}