import { useState, useEffect } from 'react';
import { useAppContext } from '../config/context';
import { useTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel';
import { Ul } from "../Components/Ul";
import { apiCall } from '../Utils/api';

export const Footer = () => {
    const { lang, app, setApp } = useAppContext();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(!app.blocks?.carrouselFooter){
            if (loading) {
                apiCall(`${process.env.REACT_APP_API_URL}/es/configuration?var=footer_carrousel`, 'get').then(res => {
                    app.blocks.carrouselFooter = res.value;
                    setApp(app);
                    setLoading(false);
                });
            }
        }
    }, []);

    const opts = {
        nav: false,
        dots: true,
        slideBy: 1,
        responsive: { 0: { items: 1 }, 600: { items: 2 }, 1200: { items: 3 } },
        loop: false
    };

    return (
        <footer>
            <div className="_301_content _301_padding_150">
                {app.blocks?.carrouselFooter && <OwlCarousel className="_301_location owl-carousel _301_alter" {...opts} dangerouslySetInnerHTML={{__html: app.blocks?.carrouselFooter}}>
                </OwlCarousel>}
            </div>
            <div className="_301_legal">
                <div className="_301_content">
                    <div className="_301_flex">
                        <div className="_301_col _301_hover _301_alter">
                            <a className="_301_copyr" href="https://301.com.co" target="_blank" rel="noreferrer">301 ©</a>
                        </div>
                        <div className="_301_col">
                            <Ul
                                className="_301_hover _301_alter _301_social"
                                options={[
                                    {
                                        external: true,
                                        href: "https://www.instagram.com/abadfaciolince/",
                                        title: <i className="fa-brands fa-instagram"></i>
                                    },
                                    {
                                        external: true,
                                        href: "https://www.facebook.com/abadfaciolinceinmobiliaria",
                                        title: <i className="fa-brands fa-facebook"></i>
                                    },
                                    {
                                        external: true,
                                        href: "https://api.whatsapp.com/send?phone=573163891622",
                                        title: <i className="fa-brands fa-whatsapp"></i>
                                    },
                                ]}
                            />
                            <Ul
                                className="_301_hover _301_alter _301_data"
                                options={[
                                    {
                                        href:`/${lang}/${t('url.work-with-us')}`,
                                        title: `${t('work-with-us')}`
                                    },
                                    {
                                        href:`/${lang}/${t('url.data-policy')}`,
                                        title: `${t('data-policy')}`
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="_301_option _301_alter _301_chat">
                        <div className="_301_icon"><i className="fa-solid fa-headset"></i></div>
                    </div>
                </div>
            </div>
            <div className="_301_whatsapp">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=573163891622"><i className="fab fa-whatsapp"/></a>
            </div>
        </footer>
    );
}
