import { useLocation } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import './App.css';
import { Footer } from './Blocks/Footer';
import { Header } from './Blocks/Header';
import { AppContextProvider } from './config/context';
import { LangRouter } from './config/routes';

function App() {
	const { pathname } = useLocation();

	return (
		<>
			<AppContextProvider>
				<Header home={['/es', '/en'].includes(pathname)} currentUrl={pathname} />
				<GoogleReCaptchaProvider
					useRecaptchaNet
					reCaptchaKey={process.env.REACT_APP_CATPCHA_KEY}
					scriptProps={{ async: true, defer: true, appendTo: 'body' }}
				>
					<LangRouter />
				</GoogleReCaptchaProvider>
				<Footer />
			</AppContextProvider>
		</>
	);
}

export default App;
