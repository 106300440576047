import { createContext, useState, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import { useLocation } from "react-router-dom";

const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
    const {pathname} = useLocation();
    const [lang, setLang] = useState(pathname.split('/').filter(s => ["",'es','en'].includes(s))[0] || "es");
    let pre = {};
    /* global banner */
    if(typeof banner !== undefined){
        if(lang === "en"){
            pre["banner-home-en"] = banner;
        }else{
            pre["banner-home"] = banner;
        }
    }

    const [app, setApp] = useState({
        blocks: pre
    });
    const [isAuth, setAuth] = useState(false);
    const { i18n } = useTranslation();

    useEffect(() => {
        if(i18n.language !== lang){
            i18n.changeLanguage(lang);
        }
    }, [lang, i18n]);

    return (
        <AppContext.Provider value={{ lang, setLang, isAuth, setAuth, app, setApp }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);