import { getStorageVar } from "./auth";

const apiCall = (url, method, params, auth = false) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    if(auth){
        const token = getStorageVar('token');
        headers.append("Authorization", `Bearer ${token.token}`);
    }
    
    const options =  {
        method,
        headers,
    };

    if(method !== "get"){
        options['body'] = JSON.stringify(params)
    }
    return fetch(url,options)
    .then(res => res.json());
}
export {
    apiCall
}